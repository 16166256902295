html {
  background-color: var(--background);
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:root { 
  --background: #F9F9F9;
  --gold-highlight: #DEC071;
  --dark-purple: #7A55B5;
  --shadow: 0 2px 8px 1px rgba(0,0,0,0.16);
}